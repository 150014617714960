import React, { useEffect, useState } from 'react';
import Loader from '../components/Loader/Loader';
import { REDTRK_URL } from '../constants';
import MoreInfo from './MoreInfo/MoreInfo';
import './Samples.css';
import SamplesBody from './SamplesBody/SamplesBody';
import mandm from '../assets/PromoImages/mms.jpeg';
import chickfila from '../assets/PromoImages/chickfila.jpeg';
import ihop from '../assets/PromoImages/ihop.jpeg';
import lays from '../assets/PromoImages/lays_samples.jpeg';
import quiznos from '../assets/PromoImages/quiznos.png';
import mcd from '../assets/PromoImages/mcd.png';
import Footer from '../components/Footer/Footer';
import fastFootIMG from '../assets/fasfood.png';
import fastFootDarkIMG from '../assets/fastfood3.png';
import candiesIMG from '../assets/candies.jpeg';
import candiesDarkIMG from '../assets/m&ms-backgr3-dark.png';
import chipsIMG from '../assets/chips.jpg';
import chipsDarkIMG from '../assets/chips_dark.png';
import mcdIMG1 from '../assets/mcsbckg1.png';
import mcdIMGdark1 from '../assets/mcsbckg1-dark.png';
import kfcIMG from '../assets/kfc.png';
import kfcIMG2 from '../assets/PromoImages/kfcv2.png';
import wendysIMG from '../assets/wendys.png';
import kfcBckrg1 from '../assets/kfcbackgr1.png';
import kfcBckrg2 from '../assets/kfcbackgr2.png';
import burgerkingIMG from '../assets/burgerking.png';
import burgerkingBckrg1 from '../assets/burgerkingbackgr1.png';
import wendysBckrg1 from '../assets/wendysbackgr1.png';
import pizzaHutbackgr1 from '../assets/pizahut.jpg';
import fortniteBackgr1 from '../assets/fortniteBackgr1.png';
import fortniteBackgr2 from '../assets/fortniteBackgr2.png';
import fortniteBackgr3 from '../assets/fortniteBackgr3.png';
import fortniteBackgr4 from '../assets/fortniteBackgr4.png';
import robloxBackgr1 from '../assets/RobloxBackgr1.png';
import robloxBackgrdark from '../assets/robloxBackgr2-dark.png';
import ps5fnpic from '../assets/PromoImages/ps5fn.jpeg';
import ps5fnpic2 from '../assets/PromoImages/ps5fn2.jpeg.png';
import ps5pic from '../assets/PromoImages/ps5_1000_toward.png';
import kitkatPic from '../assets/PromoImages/kitkatSamplesPic.png';
import kitkatBackgr1 from '../assets/kitkatBackgr1.webp';
import subwayPic from '../assets/PromoImages/Subway Collage2.png';
import subwayBackgr from '../assets/subwayBackgr2.jpg';
import cocaColaFridgePic from '../assets/cocalCola Fridge.png';
import cocaColaBackgr1 from '../assets/cocaColaBackgr1.png';
import tacobellpic from '../assets/PromoImages/tacobellpix21.png';
import tacaoBellBackgr from '../assets/tacobellBackgr1.png';
import dunkinpic from '../assets/PromoImages/dunkinpix1.png';
import dunkinBackgr1 from '../assets/dunkindonutsBackgr3.png';
import robloxsamsungs22 from '../assets/PromoImages/RobloxS22Blue.png';
import pizzaHutpic from '../assets/PromoImages/Pizza Hut Samplespng2.png';
import reesespic from '../assets/PromoImages/reseespic1.png';
import reesesBackgr1 from '../assets/reesesBackgr1.avif';
import starbuckPic from '../assets/PromoImages/starbuckspic.png';
import starbuksBackgr1 from '../assets/startbucksBackgr1.png';
import bathPic1 from '../assets/PromoImages/bathbodypic.png';
import bathBackg1 from '../assets/bathbackgr1.png';
import vicpic from '../assets/PromoImages/victoriasecretpic.png';
import vicBackgr1 from '../assets/vicBacgr1.webp';
import macpic from '../assets/PromoImages/maccosmeticspic.png';
import macBackgr from '../assets/macbackgr1.png';
import esteepic from '../assets/PromoImages/estee1.png';
import esteeBackgr1 from '../assets/esteebackgr1.png';
import arbyspic from '../assets/PromoImages/arbyspic2.png';
import arbysbackgr from '../assets/arbysbackgr1.jpg';
import kinderpic from '../assets/PromoImages/kinder2.jpeg';
import chocbackgr from '../assets/chocbackgr1.png';
import milkapic from '../assets/PromoImages/milka1.png';
import chochbackgr2 from '../assets/chockbackgr3.png';
import baskinRbackgr1 from '../assets/baskinBackgr2.png';
import baskinrobbinspic from '../assets/PromoImages/basking3.png';
import applebeespic from '../assets/PromoImages/appplebees1.png';
import appleBBackgr1 from '../assets/appleBbackgr1.png';
import burgerkingPicv2 from '../assets/PromoImages/burgerkingpic1.2.png';
import burgerkingBckrg2 from '../assets/burgerkingbackgr3.png';
import bjpic from '../assets/PromoImages/bj2-removebg-preview.png';
import bjbackr from '../assets/bjbackgr1.webp';
import monsterpic from '../assets/PromoImages/monsterpic1.png';
import monsterbackgr from '../assets/monsterbackgr1.png';
import huggiespic from '../assets/PromoImages/huggies-removebg-preview.png';
import standartBackgr1 from '../assets/backgrAI1-dark.png';
import standartBackgr2 from '../assets/backgrAI1-dark2.webp';
import carlsjrpic from '../assets/PromoImages/carlsjr2-removebg-preview.png';
import sonicpic from '../assets/PromoImages/sonic3-removebg-preview.png';
import babybackgr1 from '../assets/babybackgr1.png';
import redbullpic from '../assets/PromoImages/redbullpic1-removebg-preview.png';
import redbullBackgr from '../assets/redbullbackgr1.png';
import dovepic from '../assets/PromoImages/dovepic1-removebg-preview.png';
import doveBackgr from '../assets/dovebackgr1.png';
import inNoutpic from '../assets/PromoImages/innoutpic2-removebg-preview.png';
import inNoutBackgr from '../assets/innoutBackgr2.png';
import inNoutBackgr2 from '../assets/innoutBackgr3.png';
import sephorapic1 from '../assets/PromoImages/Sephorapic2.png';
import sephorabackgr1 from '../assets/sephorabackgr1.png';
import doritospic1 from '../assets/PromoImages/doritospic1.png';
import doritosBackgr1 from '../assets/doritosbackgr1.png';
import HeaderSamples from '../components/HeaderSamples/HeaderSamples';

const Samples = ({ pageName }) => {
  const [title, setTitle] = useState('Gift Card Giveaway! Register to Continue!');
  const [headline, setHeadline] = useState('');
  const [picURL, setpicURL] = useState('');
  const [backGr1, setBackgr1] = useState('none');
  const [backGr2, setBackgr2] = useState('none');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (pageName.includes('m&ms') && pageName.includes('samples')) {
      setTitle(`M&M's Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(mandm);
      setBackgr1(candiesIMG);
      setBackgr2(candiesDarkIMG);
    } else if (pageName.includes('chickfila') && pageName.includes('samples')) {
      setTitle(`Chick-Fil-A Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(chickfila);
      setBackgr1(fastFootIMG);
      setBackgr2(fastFootDarkIMG);
    } else if (pageName.includes('lays') && pageName.includes('samples')) {
      setTitle(` Lays Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(lays);
      setBackgr1(chipsIMG);
      setBackgr2(chipsDarkIMG);
    } else if (pageName.includes('ihop') && pageName.includes('samples')) {
      setTitle(`iHop Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(ihop);
      setBackgr1(fastFootIMG);
      setBackgr2(fastFootDarkIMG);
    } else if (pageName.includes('quiznos') && pageName.includes('samples')) {
      setTitle(`Quiznos Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(quiznos);
      setBackgr1(fastFootIMG);
      setBackgr2(fastFootDarkIMG);
    } else if (pageName.includes('mcdonalds') && pageName.includes('samples')) {
      setTitle(`McDonald's Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(mcd);
      setBackgr1(mcdIMG1);
      // setBackgr2(mcdIMGdark1);
    } else if (pageName.includes('kfc') && pageName.includes('samples') && !pageName.includes('v2')) {
      setTitle(`KFC Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(kfcIMG);
      setBackgr1(kfcBckrg1);
      // setBackgr2(fastFootDarkIMG);
    } else if (pageName.includes('kfc') && pageName.includes('samples') && pageName.includes('v2') ) {
      setTitle(`KFC Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(kfcIMG2);
      setBackgr1(kfcBckrg2);
      // setBackgr2(fastFootDarkIMG);
    }
    else if (pageName.includes('wendys') && pageName.includes('samples')) {
      setTitle(`Wendy's Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(wendysIMG);
      setBackgr1(wendysBckrg1);
      // setBackgr2(fastFootDarkIMG);
    } else if (pageName.includes('burgerking') && pageName.includes('samples') && pageName.includes('v1')) {
      setTitle(`Burger King Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(burgerkingIMG);
      setBackgr1(burgerkingBckrg1);
      // setBackgr2(fastFootDarkIMG);
    } else if (pageName.includes('fnps5') && pageName.includes('samples')) {
      setTitle(`PlayStation 5`);
      setHeadline(`You Qualify to Get`);
      setpicURL(ps5fnpic);
      setBackgr1(fortniteBackgr1);
      // setBackgr2(fortniteBackgr3);
    } else if (pageName.includes('fn2ps5') && pageName.includes('samples')) {
      setTitle(`PlayStation 5`);
      setHeadline(`You Qualify to Get`);
      setpicURL(ps5fnpic2);
      setBackgr1(fortniteBackgr1);
      // setBackgr2(fortniteBackgr2);
    } else if (pageName.includes('roblox') && pageName.includes('samsungs22')) {
      setTitle(`Samsung S22`);
      setHeadline(`You Qualify to Get`);
      setpicURL(robloxsamsungs22);
      setBackgr1(robloxBackgr1);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('pizzahut') && pageName.includes('samples')) {
      setTitle(`Pizza Hut Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(pizzaHutpic);
      setBackgr1(pizzaHutbackgr1);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('kitkat') && pageName.includes('samples')) {
      setTitle(`Kit Kat Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(kitkatPic);
      setBackgr1(kitkatBackgr1);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('subway') && pageName.includes('samples')) {
      setTitle(`Subway Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(subwayPic);
      setBackgr1(subwayBackgr);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('cocacolafridge') && pageName.includes('samples')) {
      setTitle(`Coca Cola Fridge`);
      setHeadline(`You Qualify to Get`);
      setpicURL(cocaColaFridgePic);
      setBackgr1(cocaColaBackgr1);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('tacobell') && pageName.includes('samples')) {
      setTitle(`Taco Bell Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(tacobellpic);
      setBackgr1(tacaoBellBackgr);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('dunkindonuts') && pageName.includes('samples')) {
      setTitle(`Dunkin' Donuts Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(dunkinpic);
      setBackgr1(dunkinBackgr1);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('reeses') && pageName.includes('samples')) {
      setTitle(`Reese's Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(reesespic);
      setBackgr1(reesesBackgr1);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('starbucks') && pageName.includes('samples')) {
      setTitle(`Starbucks Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(starbuckPic);
      setBackgr1(starbuksBackgr1);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('bathbodyworks') && pageName.includes('samples')) {
      setTitle(`Bath & Body Works Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(bathPic1);
      setBackgr1(bathBackg1);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('victoriassecret') && pageName.includes('samples')) {
      setTitle(`Victoria's Secret Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(vicpic);
      setBackgr1(vicBackgr1);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('maccosmetics') && pageName.includes('samples')) {
      setTitle(`MAC Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(macpic);
      setBackgr1(macBackgr);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('esteelauder') && pageName.includes('samples')) {
      setTitle(`Estée Lauder Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(esteepic);
      setBackgr1(esteeBackgr1);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('arbys') && pageName.includes('samples')) {
      setTitle(`Arby's Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(arbyspic);
      setBackgr1(arbysbackgr);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('kinder') && pageName.includes('samples')) {
      setTitle(`Kinder Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(kinderpic);
      setBackgr1(chocbackgr);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('milka') && pageName.includes('samples')) {
      setTitle(`Milka Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(milkapic);
      setBackgr1(chochbackgr2);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('baskin robbins') && pageName.includes('samples')) {
      setTitle(`Baskin Robbins Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(baskinrobbinspic);
      setBackgr1(baskinRbackgr1);
    } else if (pageName.includes('applebees') && pageName.includes('samples')) {
      setTitle(`Applebee's Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(applebeespic);
      setBackgr1(appleBBackgr1);
    } else if (pageName.includes('burgerking') && pageName.includes('samples') && pageName.includes('v2')) {
      setTitle(`Burger King Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(burgerkingPicv2);
      setBackgr1(burgerkingBckrg2);
      // setBackgr2(fastFootDarkIMG);
    } else if (pageName.includes('ben') && pageName.includes('samples') && pageName.includes('jerrys')) {
      setTitle(`Ben & Jerry's Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(bjpic);
      setBackgr1(bjbackr);
      // setBackgr2(fastFootDarkIMG);
    } else if (pageName.includes('monster') && pageName.includes('samples') && pageName.includes('energy')) {
      setTitle(`Monster Energy Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(monsterpic);
      setBackgr1(monsterbackgr);
      // setBackgr2(fastFootDarkIMG);
    } else if (pageName.includes('huggies') && pageName.includes('samples')) {
      setTitle(`Huggies Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(huggiespic);
      setBackgr1(babybackgr1);
      setBackgr2(babybackgr1);
    } else if (pageName.includes('carlsjr') && pageName.includes('samples')) {
      setTitle(`Carl's Jr Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(carlsjrpic);
      setBackgr1(fastFootIMG);
      setBackgr2(fastFootDarkIMG);
    } else if (pageName.includes('sonic') && pageName.includes('samples')) {
      setTitle(`Sonic Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(sonicpic);
      setBackgr1(fastFootIMG);
      setBackgr2(fastFootDarkIMG);
    } else if (pageName.includes('redbull') && pageName.includes('samples')) {
      setTitle(`Red Bull Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(redbullpic);
      setBackgr1(redbullBackgr);
      setBackgr2(redbullBackgr);
    } else if (pageName.includes('dove') && pageName.includes('samples')) {
      setTitle(`Dove Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(dovepic);
      setBackgr1(doveBackgr);
      setBackgr2(doveBackgr);
    } else if (pageName.includes('innout') && pageName.includes('samples')) {
      setTitle(`In-N-Out Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(inNoutpic);
      setBackgr1(inNoutBackgr);
      setBackgr2(inNoutBackgr2);
    } else if (pageName.includes('sephora') && pageName.includes('samples')) {
      setTitle(`Sephora Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(sephorapic1);
      setBackgr1(sephorabackgr1);
      setBackgr2(sephorabackgr1);
    } else if (pageName.includes('doritos') && pageName.includes('samples')) {
      setTitle(`Doritos Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(doritospic1);
      setBackgr1(doritosBackgr1);
      //setBackgr2(doritosBackgr1);
    }
    
  }, []);

  // useEffect(() => {
  //   const existingUser = window.localStorage.getItem('S&R');

  //   if (existingUser) {
  //     setLoading(true);
  //     const doLoading = setTimeout(() => {
  //       window.location.replace(
  //         `${REDTRK_URL}?email=${existingUser}&sub2=${existingUser}&sub3=repeating_user`
  //       );
  //     }, 1200);
  //     return () => clearTimeout(doLoading);
  //   }
  // }, []);

  //
  // more infor to be this Khaki color: rgb(240,230,140)

  return (
    <div>
      <HeaderSamples pageType="samples" title={title} headline={headline} />
      {/* <Header pageType="samples" backcolor="rgb(255,215,0)" /> */}
      <div className="main-container">
        {loading ? (
          <div className="loader-div">
            <h2 id="loading-text">Checking if you qualify...</h2>
            <br />
            <br />
            <Loader width="15.5em" height="12.23em" style={{ border: '1px solid black' }} />
          </div>
        ) : (
          <SamplesBody
            title={title}
            headline={headline}
            picURL={picURL}
            backgroundPic1={backGr1}
            backgroundPic2={backGr2}
          />
        )}

        <MoreInfo backcolor="rgb(240,230,140)" />
      </div>
      <Footer pageType="samples" />
    </div>
  );
};

export default Samples;
